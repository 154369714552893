import { Grid, Typography } from "@mui/material";
import { Fragment, useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { requiredRules } from "shared/helpers/validators";
import BasicAutocomplete from "shared/ui/Autocomplete";
import ButtonLoader from "shared/ui/ButtonLoader";
import TextInput from "shared/ui/TextInput";
import { ERequestStatus } from "store/enums/index.enum";
import { AppDispatch } from "store/index";
import { ITriggerServices } from "store/interfaces/triggerServices";
import { ITriggerSource } from "store/interfaces/triggerSource";
import {
  selectButtonLoadingState,
  setButtonLoading,
} from "store/slicers/common";
import { selectTriggerServiceData } from "store/slicers/triggerServices";
import {
  AddTriggerSource,
  UpdateTriggerSource,
} from "store/slicers/triggerSource";

interface IFormData {
  name: string;
  description: string;
  serviceTypeIds: ITriggerServices[] | [];
}

const AddEditTriggerSource = ({
  onSuccess,
  editData,
}: {
  onSuccess: () => void;
  editData?: ITriggerSource;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const triggerServices = useSelector(selectTriggerServiceData);
  const methods = useForm<IFormData>({
    defaultValues: {
      name: "",
      description: "",
      serviceTypeIds: [],
    },
  });
  const isLoading = useSelector(selectButtonLoadingState);

  const onSubmit = async (data: IFormData) => {
    dispatch(setButtonLoading(true));
    const ids: number[] = data.serviceTypeIds.map((i) => i.id);

    const requestData = {
      ...data,
      serviceTypeIds: ids,
    };

    if (editData) {
      const { meta } = await dispatch(
        UpdateTriggerSource({ id: editData?.id, ...requestData })
      );
      if (meta.requestStatus !== ERequestStatus.FULFILLED) {
        dispatch(setButtonLoading(false));
        return;
      }
    } else {
      const { meta } = await dispatch(AddTriggerSource(requestData));

      if (meta.requestStatus !== ERequestStatus.FULFILLED) {
        dispatch(setButtonLoading(false));
        return;
      }
    }

    const message = editData
      ? "Trigger Source Updated Successfully"
      : "Trigger Source Added Successfully";
    toast.success(message);
    dispatch(setButtonLoading(false));
    onSuccess();
  };

  const initialFetch = useCallback(async () => {
    if (editData) {
      const services = triggerServices.filter((i) =>
        editData.serviceTypeIds.includes(i.id)
      );
      methods.reset({
        name: editData.name ?? "",
        description: editData.description ?? "",
        serviceTypeIds: services,
      });
    }
  }, [editData, methods, triggerServices]);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  return (
    <Fragment>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInput
                label="Name"
                name="name"
                rules={{
                  ...requiredRules,
                  maxLength: {
                    value: 300,
                    message: "Max 300 characters",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextInput
                label="Description"
                name="description"
                rules={requiredRules}
              />
            </Grid>
            <Grid item xs={12}>
              <BasicAutocomplete
                inputLabel="Services"
                name="serviceTypeIds"
                rules={requiredRules}
                options={triggerServices || []}
                defaultValue={[]}
                optionLabel="name"
                multiple
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonLoader
                fullWidth
                onClick={methods.handleSubmit(onSubmit)}
                isLoading={isLoading}
                type="submit"
              >
                <Typography>Save</Typography>
              </ButtonLoader>
            </Grid>
          </Grid>
        </FormProvider>
      </form>
    </Fragment>
  );
};

export default AddEditTriggerSource;
